"use strict";

// Replacement of no-js for js in html tag
//document.documentElement.className = document.documentElement.className.replace('no-js','js');
// Detect mobile
let isMobile = false;
const userAgent = navigator.userAgent || navigator.vendor || window.opera;

if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(userAgent.substr(0, 4))) {
  isMobile = true;
  document.documentElement.classList.add('is-mobile');
} // When page ready


jQuery(pageReady);

function pageReady($) {
  // Get baseurl and page language
  const baseUrl = $('base').attr('href');
  const $html = $('html');
  const langue = $html.attr('lang');
  const $body = $html.find('body');
  const $window = $(window); // Window size

  let windowSizes = {
    width: 0,
    height: 0,
    change: false
  };

  function getWindowSizes() {
    const newWidth = parseInt($window.width());
    const newHeight = parseInt($window.height());
    let change;

    if (newWidth !== windowSizes.width && newHeight !== windowSizes.height) {
      change = 'both';
    } else if (newWidth !== windowSizes.width) {
      change = 'width';
    } else if (newHeight !== windowSizes.height) {
      change = 'height';
    } else {
      change = 'none';
    }

    windowSizes = {
      width: $window.width(),
      height: $window.height(),
      change: change
    };
  }

  getWindowSizes();
  $window.on('resize', getWindowSizes); // FAQ AJAX

  const $faqInner = $('.faq-inner');

  if ($faqInner.length) {
    function faqLoad(url, push) {
      if (typeof push === 'undefined') {
        push = true;
      } else {
        push = false;
      } // Loading


      $faqInner.addClass('loading'); // Load

      $.get(url, function (data) {
        const html = $(data); // Update content

        $faqInner.html(html.find('.faq-inner').html()); // Update title and url

        document.title = html.filter('title').text();

        if (push) {
          window.history.pushState({}, html.filter('title').text(), url);
        } // Remove loading


        $faqInner.removeClass('loading'); // Fancybox

        const $fancyImages = $faqInner.find('[data-zoom]');

        if ($fancyImages.length) {
          $fancyImages.fancybox({
            smallBtn: false,
            toolbar: true,
            clickOutside: "close",
            buttons: [//"zoom",
            //"share",
            "slideShow", //"fullScreen",
            //"download",
            //"thumbs",
            "close"]
          });
        }
      });
    } // On faq link click


    $body.on('click', '.faq-inner .faq-ajax', function (e) {
      e.preventDefault();
      faqLoad($(this).attr('href'));
    }); // On faq state event

    $(window).on('popstate', function (e) {
      faqLoad(document.location, false);
    }); // Fancybox

    const $fancyImages = $faqInner.find('[data-zoom]');

    if ($fancyImages.length) {
      $fancyImages.fancybox({
        smallBtn: false,
        toolbar: true,
        clickOutside: "close",
        buttons: [//"zoom",
        //"share",
        "slideShow", //"fullScreen",
        //"download",
        //"thumbs",
        "close"]
      });
    }
  } // Companies space


  const $companiesSpace = $('.company-space-form');

  if ($companiesSpace.length) {
    const $companiesForm = $companiesSpace.find('form');
    const $companiesFormSubmit = $companiesForm.find('button[type=submit]');
    const floatlabels = new FloatLabels($companiesForm[0], {});
    $companiesForm.parsley();
    $companiesSpace.find('.datepicker').datepicker({
      language: 'fr'
    }).on('changeDate', function (e) {
      $companiesForm.parsley().validate('creation');
      $(e.currentTarget).parents('.fl-wrap').addClass('fl-is-active');
    });
    $companiesForm.submit(function (e) {
      e.preventDefault();
      $companiesFormSubmit.prop('disabled', true);
      $companiesFormSubmit.data('oldText', $companiesFormSubmit.text()).text($companiesFormSubmit.data('send'));
      $.ajax({
        method: 'post',
        url: ajax.url,
        data: {
          data: $companiesForm.serialize(),
          security: ajax.nonce,
          action: 'companies-form-validation'
        },
        dataType: 'json',
        success: function (data) {
          $companiesFormSubmit.prop('disabled', false);
          $companiesFormSubmit.text($companiesFormSubmit.data('oldText'));

          if (data.success) {
            // Clear form
            $companiesForm.trigger('reset'); // Show message

            $companiesSpace.find('.alert').remove();
            $('<div class="alert alert-success">' + data.success + '</div>').insertBefore($companiesFormSubmit.parent());
          } else if (data.error) {
            // Show message
            $companiesSpace.find('.alert').remove();
            $('<div class="alert alert-error">' + data.error + '</div>').insertBefore($companiesFormSubmit.parent());
          }
        },
        error: function () {
          // Show message
          $companiesSpace.find('.alert').remove();
          $('<div class="alert alert-error">' + ajax.error + '</div>').insertBefore($companiesFormSubmit.parent());
        }
      });
    });
  } // Timeline


  const $timelineContainer = $('.timeline-container');

  if ($timelineContainer.length) {
    const $timelineLeft = $timelineContainer.find('.timeline-left');
    const $timelineRight = $timelineContainer.find('.timeline-right');
    const $timelineNav = $timelineRight.find('.timeline-nav');
    const $timelineInner = $timelineRight.find('.timeline-right-inner'); // Fix height

    function timelineHeight() {
      // Set height
      if (windowSizes.width > 1200) {
        // Reset
        $timelineRight.height(0);
        $timelineRight.height($timelineLeft.outerHeight());
      } else {
        $timelineRight.attr('style', null);
      } // Hide / show btns


      if ($timelineInner[0].scrollHeight > $timelineInner[0].clientHeight) {
        $timelineNav.show();
      } else {
        $timelineNav.hide();
      }
    }

    timelineHeight();
    $window.load(timelineHeight);
    $window.resize(timelineHeight); // Scroll buttons

    let timelineInterval;
    $timelineRight.on('mousedown touchstart', '.prev', function () {
      clearInterval(timelineInterval);
      timelineInterval = setInterval(function () {
        $timelineInner.animate({
          scrollTop: '-=20px'
        }, 0);
      }, 10);
    });
    $timelineRight.on('mousedown touchstart', '.next', function () {
      clearInterval(timelineInterval);
      timelineInterval = setInterval(function () {
        $timelineInner.animate({
          scrollTop: '+=20px'
        }, 0);
      }, 10);
    });
    $timelineRight.on('mouseup mouseleave touchend', '.prev,.next', function () {
      clearInterval(timelineInterval);
    });
  } // Direct


  const $directContainer = $('.direct-container');

  if ($directContainer.length) {
    const $captions = $directContainer.find('.direct-captions'); // Swiper

    const swiper = new Swiper('.swiper', {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      //navigation: {
      //nextEl: '.swiper-button-next',
      //prevEl: '.swiper-button-prev',
      //},
      on: {
        slideChange: function (swiper) {
          // Show caption
          $captions.find('.caption').hide();
          $captions.find('[data-index="' + swiper.realIndex + '"]').show();
        }
      }
    });
  }
}